/**
 * Checkbox override
 */
 @mixin custom-checkbox {
    margin-top: 0;
    opacity: 0;
    position: relative;
    margin-left:15px;
    + label {
        position: relative;
        &::before{
            content: "";
            display: inline-block;
            height: 16px;
            width: 16px;
            border: 1px solid;
            position: absolute;
            top: 3px;   
            left: 0px;
            left: -20px;
        }
    }
    &:checked {
        + label {
            &::after {
                content: "";
                display: inline-block;
                height: 6px;
                width: 9px;
                border-left: 2px solid;
                border-bottom: 2px solid;
                transform: rotate(-45deg);
                position: absolute;
                left: 4px;
                top: 7px;
                left: -17px;
            }
        }
    }
}
