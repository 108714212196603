/*
 * CSS custom properties
 * We can finally spell "colour" correctly 👍
 *
 * Custom properties can be applied to html or :root
 * :root will target root selector of SVGs as well, for example
 */
:root {
	@each $prop, $variable in $root-variables {
		#{$prop}: $variable;
	}
}
