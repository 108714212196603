/**
 * Project variables
 *
 * All the Sass variables used throughout the theme. This file shouldn't output
 * any actual CSS on compilation.
 */

/* Colours */

// Base colours
$grey-1: #fafafa;
$grey-2: #f7f7f7;
$grey-3: #eeeeee;
$grey-4: #e0e0e0;
$grey-5: #bdbdbd;
$grey-6: #9e9e9e;
$grey-7: #757575;
$grey-8: #616161;
$grey-9: #424242;
$grey-10: #212121;
$white: #fff;
$sun-black: #231f20;    
$sun-gold: #fdb614;
$sun-grey: #91c9d6;
$sun-red: #c22a62;
$sun-dark-blue: #1C95B0;
$sun-blue: #00c7c7;
$sun-cyan: #00fefe; 
$sun-aqua: #58C4C6;
$sun-orange: #F55D50;


/*
 * Colour variables.
 *
 * If you alter variable names, also update EditorColourPalette.php
 * These cascade to block editor colour palette.
 *
 * Sass variables need to be #{interpolated}
 *
 * Don't use --text as this causes a collision with WordPress default classes.
 */
$colour-map: (
    --sun-gold: #{$sun-gold},
    --sun-gold-text: #{$sun-black},
    --sun-gold-hover: #{$sun-gold},
    --sun-grey: #{$sun-grey},
    --sun-grey-text: #{$sun-black},
    --sun-grey-hover: #{$sun-grey},
    --sun-red: #{$sun-red},
    --sun-red-text: #{$white},
    --sun-red-hover: #{$sun-red},
    --sun-dark-blue: #{$sun-dark-blue},
    --sun-dark-blue-text: #{$white},
    --sun-dark-blue-hover: #{$sun-dark-blue},
    --sun-blue: #{$sun-blue},
    --sun-blue-text: #{$sun-black},
    --sun-blue-hover: #{$sun-blue},
    --sun-cyan: #{$sun-cyan},
    --sun-cyan-text: #{$sun-black},
    --sun-cyan-hover: #{$sun-cyan},
    --sun-aqua: #{$sun-aqua},
    --sun-aqua-text: #{$sun-black},
    --sun-aqua-hover: #{$sun-grey},
    --sun-orange: #{$sun-orange},
    --sun-orange-text: #{$sun-black},
    --sun-orange-hover: #{$sun-grey},
    --white: #{$white},
    --white-text: #{$sun-black},
    --white-hover: #{$white},
    --black: #{$sun-black},
    --black-text: #{$white},
    --black-hover: #{$sun-black},
    --body-text: #{$sun-black},
    --body-text-text: #{$white},
    --body-text-hover: #{$sun-black},
    --link: #{$sun-dark-blue},
    --link-text: #{$white},
    --link-hover: #{$sun-dark-blue},
    --focus-ring: #{$sun-dark-blue},
    --grey-1: #{$grey-1},
    --grey-2: #{$grey-2},
    --grey-3: #{$grey-3},
    --grey-4: #{$grey-4},
    --grey-5: #{$grey-5},
    --grey-6: #{$grey-6},
    --grey-7: #{$grey-7},
    --grey-8: #{$grey-8},
    --grey-9: #{$grey-9},
    --grey-10: #{$grey-10},
    --header-background-color: #{$sun-red},
    --header-color: #{$white},
    --error: #d0011b,
);

/*
 * Layout variables.
 *
 * Used for the wrapper and more layout options.
 */
$layout-map: (
    --horizontal-gap: 1rem,
    --vertical-gap: 0,
    --max-content-width: 730px,
    --max-container-width: 1110px,
);

/**
 * Z-Index variables.
 *
 * Z-Indicies should be defined in increments of 5 (avoid 99999999999 etc.)
 */
$z-index-map: (
    cover-block-video-background: 0,
    cover-block-background-gradient: 5,
    cover-block-inner: 5,
    old-cover-block-text: 5,
    school-search-form: 5,
    header: 300,
    screen-reader-text: 100000 // Above WP Toolbar,,,,,,,,,,,,,,,,,,,,
);

// merge maps to be output in the :root
$root-variables: map-merge($colour-map, $layout-map);

// Base background-color
$base-background-colour: white;

// Footer background-color
$footer-background-colour: #FBFBFB;

/* Typography */

// Base typography
$base-font-size: 16px;
$base-line-height: 1.5;
$base-font-weight: 400;

// Main nav typography
$main-nav-font-size: 15px;

// Font stacks
$sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans,
    Ubuntu, Cantarell, "Helvetica Neue", sans-serif; // System font stack
$serif: Georgia, "Times New Roman", Times, serif;
$monospace: Menlo, Monaco, Consolas, "Courier New", monospace;

// Default font
$base-font: 'Poppins', $sans-serif;

$heading-font: 'Poppins', $sans-serif;

/*
 * Breakpoint variables.
 *
 * Used for bp() mixin and bp() function
 */
$breakpoint-map: (
    sm: 480px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
);

/**
 * SVG icon defaults
 */
$icon-height: 44px; //Recommended minimum size from Apples Human Interface Guidelines
$icon-width: 44px; //Recommended minimum size from Apples Human Interface Guidelines
