/*
 * Styles for /wp-login.php
 */
/* utils */
@import "utils/utils";

/* reset */
@import "common/reset";
@import "common/screen-reader";
@import "common/type";
@import "common/root";

.login {
    @include background-colour(--white);

    /* Set the size of the login logo */
    h1 a {
        background-image: url("../img/logo-login.svg");
        background-size: 250px;
        width: 320px;
        height: 81px;
    }
    form {
        border: 0;
        padding: 1.5rem 1rem 2rem;
        overflow: initial;
    }
    /* Hide the "Back to (website)" link */
    p#backtoblog {
        display: none;
    }
    .privacy-policy-page-link a,
    #backtoblog a,
    #nav a {
        @include colour(--link);
        font-size: 1rem;
        text-decoration: underline;
    }
    /* Hide the "Log in" link on the last password page, as it's pointless*/
    &.login-action-lostpassword p#nav {
        display: none;
    }
    /* Center align the Lost Password link */
    &.login-action-login p#nav {
        text-align: center;
    }
    input#user_login,
    input#user_pass {
        @include background-colour(--grey-2);
        border-radius: 0;
        border: 0;
        font-size: 1rem;
        padding: 10px 20px;

        &:focus {
            outline: auto;
        }
    }
    .wp-hide-pw,
    .wp-hide-pw:hover {
        @include colour(--body-text);
    }
    .wp-hide-pw:focus {
        outline: auto !important;
        border: 0;
        box-shadow: none;
    }
    input#wp-submit {
        @include button;
        @include button-colours(--link);
        border-radius: 0;

        &:focus {
            outline: auto;
            box-shadow: none;
        }
    }
    .message {
        border: 2px solid;
        @include border-colour(--link);
        line-height: 1;
        box-shadow: none;
        margin: 0 1rem;
    }
    form {
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .button-primary {
        text-shadow: none;
    }
}

// Match colours to modal shown when the login cookie expires
.wp-admin {
    #wp-auth-check-wrap #wp-auth-check {
        @include background-colour(--white);
    }
}
