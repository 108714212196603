/*
 * Base typography
 */

html {
    font-size: 100%;
    font-size: $base-font-size;
    line-height: $base-line-height;
}

body {
    font-family: $base-font;
    font-size: $base-font-size;
    @include colour(--body-text);
    background-color: $base-background-colour;
    font-weight: $base-font-weight;
}

// Headings
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.wp-block-heading h1,
.wp-block-heading h2,
.wp-block-heading h3,
.wp-block-heading h4,
.wp-block-heading h5,
.wp-block-heading h6 {
    font-family: $heading-font;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: #{$base-line-height}rem;

    &.is-style-underline {
        text-decoration: underline;
    }
}

// Heading-specific sizes
// Use heading classes to change visual display while retaining semantic hierarchy
h1,
.h1,
.wp-block-heading h1 {
    font-size: 2.25rem;
    line-height: 2.75rem;
    font-weight: 600;
    @include bp(md) {
        font-size: 2.5rem;
        line-height: 3rem;
    }
}

h2,
.h2,
.wp-block-heading h2 {
    font-size: 1.5rem;
    line-height: 2.375rem;
    font-weight: 600;

    @include bp(md) {
        font-size: 1.75rem;
        line-height: 2.625rem;
    }
}

h3,
.h3,
.wp-block-heading h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;

    @include bp(md) {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }
}

h4,
.h4,
.wp-block-heading h4 {
    font-size: 1.125rem;
    line-height: 1.375rem;

    @include bp(md) {
        font-size: 1.375rem;
        line-height: 1.625rem;
    }
}

h5,
.h5,
.wp-block-heading h5 {
    font-size: 1.112rem;
    line-height: 1.362rem;

    @include bp(md) {
        font-size: 1.362rem;
        line-height: 1.612rem;
    }
}

h6,
.h6,
.wp-block-heading h6 {
    font-size: 1.056rem;
    line-height: 1.306rem;

    @include bp(md) {
        font-size: 1.306rem;
        line-height: 1.556rem;
    }
}

// Paragraphs
p,
p.has-regular-font-size,
p.has-normal-font-size,
.is-regular-text,
.wp-block p,
.wp-block p.has-regular-font-size,
.wp-block p.has-normal-font-size,
.wp-block-button__link {
    margin: 0 0 #{$base-line-height}rem;

    font-family: $base-font;

    font-size: 1.125rem;
    line-height: 1.5;
}

p.has-small-font-size,
.is-small-text,
.wp-block p.has-small-font-size {
    font-size: 1rem;
    line-height: 1.5;
}

p.has-large-font-size,
.is-large-text,
.wp-block p.has-large-font-size {
    font-size: 1.25rem;
    line-height: 1.333;
}

.has-larger-font-size, // Not used now, kept because of backward compatibility.
.is-larger-text,
.has-huge-font-size {
    font-size: 42px;
}

// Don't show the drop cap when editing the paragraph's content. It causes a
// number of bugs in combination with `contenteditable` fields. The caret
// cannot be set around it, caret position calculation fails in Chrome, and
// typing at the end of the paragraph doesn't work.
p.has-drop-cap:not(:focus)::first-letter {
    float: left;
    font-size: 8.4em;
    line-height: 0.68;
    font-weight: 100;
    margin: 0.05em 0.1em 0 0;
    text-transform: uppercase;
    font-style: normal;
}

p.has-background {
    padding: 20px 30px;
}

p.has-text-color a {
    color: inherit;
}

// Small
small,
.small {
    font-size: 90%;
}

// Unordered and Ordered lists
ul,
ol,
.wp-block ul,
.wp-block ol {
    margin-top: 0;
    margin-bottom: #{$base-line-height}rem;
    ul,
    ol {
        margin-bottom: 0;
    }

    &.is-style-icon {
        list-style-image: url("../img/list-icon.svg");

        li {
            padding-left: 5px;
        }
    }
}
